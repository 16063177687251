var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{ name: 'Dashboard', path: '/dashboard',  icon: 'ni ni-ungroup text-orange' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Transactions',
            icon: 'ni ni-chart-pie-35 text-info',
            path: '/transaction'
          }}}),_c('sidebar-item',{attrs:{"link":{
                        name: 'Maps',
                        icon: 'ni ni-map-big text-primary'
                      }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Bus Locator', path: '/maps/google' }}})],1),_c('sidebar-item',{attrs:{"link":{
                        name: 'Bus Management',
                        icon: 'ni ni-bus-front-12 text-primary'
                      }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Bus List', path: '/bus' }}})],1),_c('sidebar-item',{attrs:{"link":{
                        name: 'PAO Management',
                        icon: 'ni ni-single-02 text-primary'
                      }}},[_c('sidebar-item',{attrs:{"link":{ name: 'PAO List', path: '/pao' }}})],1),_c('sidebar-item',{attrs:{"link":{
                        name: 'Driver Management',
                        icon: 'ni ni-single-02 text-primary'
                      }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Driver List', path: '/driver' }}})],1),_c('sidebar-item',{attrs:{"link":{
                        name: 'Fare Management',
                        icon: 'ni ni-money-coins text-primary'
                      }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Fare List', path: '/fare' }}})],1),_c('sidebar-item',{attrs:{"link":{
                        name: 'User Management',
                        icon: 'ni ni-circle-08 text-primary'
                      }}},[_c('sidebar-item',{attrs:{"link":{ name: 'User List', path: '/users' }}})],1)],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }